<template>
  <a-layout>
    <a-layout-sider :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }">
      <div class="logo" />
      <a-menu v-model:selectedKeys="keys" theme="dark" mode="inline" @click="pushCheckInCode">
        <a-menu-item key="1" >
          <user-outlined />
          <span class="nav-text">Check In Code</span>
        </a-menu-item>
        <a-menu-item key="2" @click="pushCheckInCode">
          <video-camera-outlined />
          <span class="nav-text">News</span>
        </a-menu-item>
        <a-menu-item key="3">
          <upload-outlined />
          <span class="nav-text">FAQ</span>
        </a-menu-item>
        <a-menu-item key="4">
          <upload-outlined />
          <span class="nav-text">Sign Out</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout :style="{ marginLeft: '200px' }">
      <a-layout-header :style="{ background: '#fff', padding: 0 }" >
        <h2 style="padding-left: 20px">
          i-Health Admin System
        </h2>
      </a-layout-header>
      <a-layout-content :style="{ margin: '24px 16px 0', overflow: 'initial' }">
        <div :style="{ padding: '24px', background: '#fff' }">
          <router-view/>
        </div>
      </a-layout-content>
      <a-layout-footer :style="{ textAlign: 'center' }">
        iHealth Copyright (C) 2021-2022
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import { UserOutlined, VideoCameraOutlined, UploadOutlined, BarChartOutlined, CloudOutlined, AppstoreOutlined, TeamOutlined, ShopOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref} from 'vue';
import {useRouter} from 'vue-router'
import { getAuth, signOut } from "firebase/auth";


const selectedKeys = []
export default {
  name: "Center",
  components: {
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    BarChartOutlined,
    CloudOutlined,
    AppstoreOutlined,
    TeamOutlined,
    ShopOutlined,
  },
  setup() {
    const router = useRouter()
    const keys = ref(selectedKeys)
    keys.value = ['1']
    const pushCheckInCode = (item) => {
      if (item.key === '1') {
        console.log("1")
        router.push({path: "/Admin/CheckInCode"})
      }
      if (item.key === '2') {
        console.log("2")
        router.push({path: "/Admin/News"})
      }
      if (item.key === '3') {
        console.log("3")
        router.push({path: "/Admin/FAQ"})
      }
      if (item.key === '4') {
        console.log("sign out")
        const auth = getAuth();
        signOut(auth).then(res => {
          console.log(res)
        }).catch(err=>{
          console.log(err)
        })
      }
    };
    return {
      keys,
      pushCheckInCode
    };
  },
}
</script>

<style scoped>
#components-layout-demo-fixed-sider .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}
</style>