<template>
  <div class="home">
    <div style="margin-top: 100px" id="login"/>
  </div>
</template>

<script>
// @ is an alias to /src
import * as firebaseui from "firebaseui";
import firebase from "firebase/compat/app";

export default {
  name: 'Home',
  mounted() {
    var uiLogin = new firebaseui.auth.AuthUI(firebase.auth());
    uiLogin.start('#login', {
      signInOptions: [
        {
          provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          defaultCountry: 'CN', // Set default country to the United Kingdom (+44).
          // For prefilling the national number, set defaultNationNumber.
          // This will only be observed if only phone Auth provider is used since
          // for multiple providers, the NASCAR screen will always render first
          // with a 'sign in with phone number' button.
          defaultNationalNumber: '13400000000',
        },
        // firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          console.log(authResult)
          return true;
        },
        signInFailure: function (err){
          console.log(err)
        },
        uiShown: function() {
          // The widget is rendered.
          // Hide the loader.
          // document.getElementById('loader').style.display = 'none';
        }
      },
      signInSuccessUrl: '/Admin/CheckInCode'
      // Other config options...
    });
  }
}

</script>
